import * as React from 'react';
import { Formik, Form, Field, ErrorMessage, FormikHelpers } from "formik";
import * as Yup from "yup";
import axios from "axios";

interface IAppProps { 
}

interface FormValues {
  firstName: string;
  lastName: string;
  email: string;
  purpose: string;
}

const SignupSchema = Yup.object().shape({
  firstName: Yup.string()
    .required("Required"),
  lastName: Yup.string().required("Last name Required"),
  email: Yup.string().email("Invalid email").required("Required"),
  purpose: Yup.string().required("Please select purpose"),
});

export function Modal ({}: IAppProps) { 

    const el: any = document.querySelector('html');  
    const [isShown, setIsShown] = React.useState(false)
    const [tab, setTab] = React.useState(false)
    const [isLoading, setLoader] = React.useState(false);

    React.useEffect(()=> {
        if(isShown){
            el.style.overflow = 'hidden';
            } else {
            el.style.overflow = 'auto';
        }
    }, [isShown])  

    const clickHandler =()=> {
        setIsShown(false)
        setTab(false)
    }

    const submitForm = async (
      values: FormValues,
      formik: FormikHelpers<FormValues>
    ) => {
      const { firstName, lastName, email, purpose } = values;
      try {
        const payload = {
          merge_fields: {
            FNAME: firstName,
            LNAME: lastName,
          },
          email_address: email,
          purpose: purpose
        };

        setLoader(true);
        await axios.post(
          "/.netlify/functions/priority-access-subscription",
          payload
        );
        // alert("Contact details added successfully.");
        formik.resetForm();
        setLoader(false);
        setTab(true);
      } catch (error: any) {
        setLoader(false);
        if (error.response) {
          if (error.response.status === 400) {
            alert(error.response.data.message);
          } else {
            console.log(error.response);
            alert(error.message);
          }
        } else {
          alert(
            "Request failed. Please check your internet connection and try again."
          );
        }
      }
    };

    return (
      <div className=" W-fit relative ">
        <button
          onClick={() => setIsShown(true)}
          className=" gap-3 flex items-center font-medium text-white px-8 rounded-[40px] bg-[#DE644A] mt-6 h-[40px] "
        >
          Join Priority List
          <svg
            width="12"
            height="9"
            viewBox="0 0 12 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.34996 8.96667L6.63329 8.26667L9.43329 5.46667H0.666626V4.46667H9.43329L6.61663 1.65L7.33329 0.950001L11.35 4.96667L7.34996 8.96667Z"
              fill="white"
            />
          </svg>
        </button>
        {isShown && (
          <div className=" fixed inset-0 flex lg:px-0 px-6 z-20 justify-center items-center overflow-hidden ">
            {!tab && (
              <Formik
                initialValues={{
                  // @ts-ignore
                  firstName: "",
                  // @ts-ignore
                  lastName: "",
                  // @ts-ignore
                  email: "",
                  // @ts-ignore
                  purpose: null,
                }}
                validationSchema={SignupSchema}
                onSubmit={submitForm}
              >
                {(formik) => (
                  <Form className=" lg:w-[380px] relative rounded-lg bg-white z-50  ">
                    <div className=" w-full flex items-center justify-between px-6 py-3 border-b border-[#e0e0e0] ">
                      <p
                        style={{ fontFamily: "Grotesque" }}
                        className=" font-bold text-[20px]  "
                      >
                        Join priority list
                      </p>
                      <svg
                        role="button"
                        onClick={() => clickHandler()}
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1.22505 13.825L0.175049 12.775L5.95005 6.99999L0.175049 1.22499L1.22505 0.174988L7.00005 5.94999L12.7751 0.174988L13.8251 1.22499L8.05005 6.99999L13.8251 12.775L12.7751 13.825L7.00005 8.04999L1.22505 13.825Z"
                          fill="#1B1C1E"
                        />
                      </svg>
                    </div>

                    <Form className="  text-left w-full px-6 py-5 ">
                      <p className=" font-medium text-sm text-[#616161] ">
                        What would you like to do on geeva?
                      </p>
                      <div className=" w-full grid grid-cols-3 gap-4 my-3 ">
                        <div className=" w-full border border-[#eee] rounded-lg h-[32px] flex gap-2 justify-center items-center ">
                          <Field
                            type="radio"
                            name="purpose"
                            value="Give"
                            className=" w-[16px] h-[16px] rounded-full border border-[#e0e0e0] "
                          />
                          <p className=" text-[#424242] text-xs font-medium ">
                            Give
                          </p>
                        </div>
                        <div className=" w-full border border-[#eee] rounded-lg h-[32px] flex gap-2 justify-center items-center ">
                          <Field
                            type="radio"
                            name="purpose"
                            value="Receive"
                            className=" w-[16px] h-[16px] rounded-full border border-[#e0e0e0] "
                          />
                          <p className=" text-[#424242] text-xs font-medium ">
                            Receive
                          </p>
                        </div>
                        <div className=" w-full border border-[#eee] rounded-lg h-[32px] flex gap-2 justify-center items-center ">
                          <Field
                            type="radio"
                            name="purpose"
                            value="Both"
                            className=" w-[16px] h-[16px] rounded-full border border-[#e0e0e0] "
                          />
                          <p className=" text-[#424242] text-xs font-medium ">
                            Both
                          </p>
                        </div>
                        <ErrorMessage
                          component="div"
                          className="text-red-700"
                          name="purpose"
                        />
                      </div>
                      <div className=" flex gap-1 lg:gap-2 lg:flex-row flex-col ">
                        <div className=" w-full ">
                          <p className="  font-medium text-sm text-[#616161] ">
                            First Name
                          </p>
                          <Field
                            id="firstName"
                            name="firstName"
                            placeholder="First Name"
                            className=" my-3 px-3 border-[#e0e0e0] h-[40px] rounded border text-sm font-medium w-full"
                          />
                          <ErrorMessage
                            component="div"
                            className="text-red-700"
                            name="firstName"
                          />
                        </div>
                        <div className=" w-full ">
                          <p className="  font-medium text-sm text-[#616161] ">
                            Last Name
                          </p>
                          <Field
                            id="lastName"
                            name="lastName"
                            placeholder="Last Name"
                            className="my-3 px-3 border-[#e0e0e0] h-[40px] rounded border text-sm font-medium w-full "
                          />
                          <ErrorMessage
                            component="div"
                            className="text-red-700"
                            name="lastName"
                          />
                        </div>
                      </div>
                      <p className="  font-medium text-sm text-[#616161] ">
                        Email
                      </p>
                      <Field
                        id="email"
                        name="email"
                        placeholder="Email"
                        className=" my-3 px-3 border-[#e0e0e0] h-[40px] rounded border text-sm font-medium w-full"
                      />
                      <ErrorMessage
                        component="div"
                        className="text-red-700"
                        name="email"
                      />
                    </Form>

                    <div className=" w-full flex justify-end px-6 py-4 border-t border-[#e0e0e0] ">
                      <button
                        disabled={!formik.isValid || !formik.dirty || isLoading}
                        type="submit"
                        className=" gap-3 flex items-center font-medium text-white px-8 rounded-[40px] bg-[#DE644A]  h-[40px] "
                      >
                        {isLoading ? "Loading..." : "Join"}
                        <svg
                          width="12"
                          height="9"
                          viewBox="0 0 12 9"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M7.34996 8.96667L6.63329 8.26667L9.43329 5.46667H0.666626V4.46667H9.43329L6.61663 1.65L7.33329 0.950001L11.35 4.96667L7.34996 8.96667Z"
                            fill="white"
                          />
                        </svg>
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            )}
            {tab && (
              <div className=" lg:w-[380px] bg-white z-50 relative rounded-xl  ">
                <div className=" w-full flex items-center justify-end p-5">
                  <svg
                    role="button"
                    onClick={() => clickHandler()}
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.22505 13.825L0.175049 12.775L5.95005 6.99999L0.175049 1.22499L1.22505 0.174988L7.00005 5.94999L12.7751 0.174988L13.8251 1.22499L8.05005 6.99999L13.8251 12.775L12.7751 13.825L7.00005 8.04999L1.22505 13.825Z"
                      fill="#1B1C1E"
                    />
                  </svg>
                </div>
                <div className=" w-full flex flex-col items-center py-7 px-6 ">
                  <svg
                    width="41"
                    height="40"
                    viewBox="0 0 41 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_612_4769)">
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M10.7162 4.36579C10.3687 4.24979 9.44209 4.24979 8.97876 4.24979C7.93626 4.24979 7.0096 4.48181 6.3146 4.82981C3.41878 5.98985 1.44961 8.77397 1.21795 11.9061C1.21795 12.1381 1.21795 12.4861 1.21795 12.7181L0.754616 26.0587C0.754616 26.4067 0.754616 26.5226 0.754616 26.8707C0.754616 27.9147 0.638782 28.9588 0.638782 30.0028L0.522949 31.6268V31.7429V32.3228C0.522949 33.0189 0.754616 33.947 0.870447 34.411C1.56545 36.731 3.30294 38.5872 5.61959 39.3992C6.43043 39.7472 7.47293 39.9791 8.51543 39.9791H8.86292C9.90542 39.9791 10.9479 39.7472 11.8746 39.2833L12.5696 38.9352C12.6854 38.8191 12.8012 38.8191 12.9171 38.7032C13.1487 38.5872 13.3804 38.4711 13.4962 38.2392C13.8437 38.0071 14.4229 37.5432 14.5387 37.1951C15.0021 37.427 15.3496 38.2392 16.9712 38.9352C17.4346 39.1671 17.8979 39.3992 18.4771 39.5151C19.0562 39.6313 19.5196 39.7472 20.0987 39.7472H21.0254C22.6469 39.6313 24.1529 39.1671 25.427 38.2392C25.6587 38.123 25.7745 38.0071 26.0061 37.775C26.1221 37.6591 26.1221 37.6591 26.2378 37.5431C26.3537 37.427 26.3537 37.427 26.4695 37.3111L27.5121 38.2392C28.2069 38.8191 29.2496 39.2832 30.1761 39.5151C30.7554 39.6313 31.3345 39.7472 31.7978 39.7472H32.4928C35.157 39.6313 37.7054 38.123 39.0953 35.803C39.2112 35.571 39.3269 35.3391 39.4429 35.107C40.022 33.947 40.2536 32.7868 40.2536 31.3949C40.2536 30.3509 39.7903 28.8428 39.5586 27.7988L38.0528 20.8384C37.8212 19.6784 37.5895 18.5184 37.2421 17.3583C37.1261 16.7783 37.0104 16.1983 36.8945 15.6182L35.7361 10.398C35.5044 9.23799 34.9253 6.33787 34.462 5.29383C33.4195 2.85773 31.4504 1.11767 28.6703 0.537642C26.7012 0.18963 24.6163 0.537642 22.8786 1.58169C22.5312 1.81369 22.6469 1.81369 22.4155 1.58169C22.2995 1.46568 22.1838 1.46568 22.0679 1.34967C21.9521 1.23367 21.8362 1.23367 21.7204 1.11767C19.7512 -0.0423794 17.4346 -0.274388 15.2337 0.305634C13.4962 0.769653 11.9904 2.04571 10.9479 3.43777C10.8321 3.66977 10.7162 3.90177 10.4846 4.13379L10.7162 4.36579Z"
                        fill="#032857"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M9.45495 7.48221C12.1191 7.59823 14.2041 9.80231 14.0883 12.4704L13.3933 32.0751C13.2775 34.7432 11.0766 36.8313 8.41246 36.7153C5.74831 36.5994 3.66332 34.3951 3.77914 31.7271L4.47414 12.1224C4.58998 9.45429 6.79081 7.36621 9.45495 7.48221Z"
                        fill="#FDC921"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M17.2039 3.55438C19.868 3.20636 22.1847 5.06244 22.5321 7.73054L25.4279 31.3955C25.7755 34.0636 23.8064 36.4997 21.258 36.7316C18.5939 37.0796 16.2772 35.2236 15.9297 32.5555L13.0339 8.89058C12.6864 6.22248 14.6555 3.78638 17.2039 3.55438Z"
                        fill="#E26C27"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M26.1215 3.97328C28.67 3.39326 31.3341 5.01732 31.7975 7.56942L36.8941 30.8863C37.4732 33.4384 35.8515 36.1066 33.1873 36.6865C30.6391 37.2666 27.9749 35.6425 27.5116 33.0904L22.4149 9.7735C21.8358 7.2214 23.4574 4.5533 26.1215 3.97328Z"
                        fill="#D84727"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_612_4769">
                        <rect
                          width="40"
                          height="40"
                          fill="white"
                          transform="translate(0.5)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                  <p
                    style={{ fontFamily: "Grotesque" }}
                    className=" text-[#1B1C1E] text-[20px] font-bold my-5 "
                  >
                    Thank You!
                  </p>
                  <p className=" font-medium text-sm text-[#616161] leading-[24px] ">
                    This journey starts with you <br/> (actually because of you). <br/> While we work on a better way to share, we’ll keep you posted. Every step of the way.
                  </p>

                  <button 
                  onClick={()=> setIsShown(false)}
                      className=" gap-3 flex items-center font-medium text-white px-8 rounded-[40px] bg-[#DE644A] mt-6 h-[40px] "
                    >
                      Close
                  </button>
                </div>
              </div>
            )}

            <div
                onClick={() => clickHandler()}
                className=" fixed z-30 bg-black bg-opacity-25 inset-0 "
            />
          </div>
        )}
        {/* {isShown && (
          <div
            onClick={() => clickHandler()}
            className=" fixed z-10 bg-black bg-opacity-25 inset-0 "
          />
        )} */}
      </div>
    );
}
