import React from 'react'
import Marquee from 'react-fast-marquee'

export default function CategoriesSection() {
    return (
        <div className=' w-full lg:flex-row flex-col flex px-6 lg:px-[148px] pt-12 lg:items-center gap-4 pb-6 ' >
            <p style={{fontFamily: 'Grotesque'}} className=' max-w-[250px] text-[24px] font-bold ' >Exciting categories to choose from</p>
            <div id="element" className=' w-full lg:w-[60vw] overflow-x-auto flex gap-3 lg:gap-4 h-10 ml-auto text-[#424242] ' >
                {/* <Marquee speed={200} >  */}
                    <button className=' border font-normal lg:mx-3 outline-none rounded-[40px] text-sm gap-2 flex items-center border-[#F8DAC9] px-4 h-[40px] ' >
                        <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.125 9L0.125 3.8L6.725 0H8C8.26667 0.75 8.64167 1.4375 9.125 2.0625C9.60833 2.6875 10.2333 3 11 3C11.7833 3 12.4167 2.6875 12.9 2.0625C13.3833 1.4375 13.75 0.75 14 0H15.275L21.85 3.825L18.875 9L17 7.975V13.375L15.5 14.65V5.425L18.325 6.975L19.8 4.375L15.1 1.625C14.6667 2.50833 14.0833 3.20833 13.35 3.725C12.6167 4.24167 11.8333 4.5 11 4.5C10.1833 4.5 9.40417 4.24167 8.6625 3.725C7.92083 3.20833 7.33333 2.50833 6.9 1.625L2.175 4.375L3.7 6.975L6.5 5.425V11.05C6.25 11.0833 5.99583 11.1375 5.7375 11.2125C5.47917 11.2875 5.23333 11.3833 5 11.5V7.975L3.125 9ZM3.675 16.275L2.7 15.125L4.675 13.45C5.05833 13.1333 5.48333 12.8917 5.95 12.725C6.41667 12.5583 6.89167 12.475 7.375 12.475C7.85833 12.475 8.32917 12.5583 8.7875 12.725C9.24583 12.8917 9.66667 13.1333 10.05 13.45L12.95 15.925C13.1833 16.125 13.4458 16.2708 13.7375 16.3625C14.0292 16.4542 14.3333 16.5 14.65 16.5C14.95 16.5 15.25 16.4542 15.55 16.3625C15.85 16.2708 16.1167 16.125 16.35 15.925L18.325 14.2L19.3 15.375L17.325 17.05C16.9417 17.3667 16.5208 17.6042 16.0625 17.7625C15.6042 17.9208 15.1333 18 14.65 18C14.1667 18 13.6917 17.9208 13.225 17.7625C12.7583 17.6042 12.3333 17.3667 11.95 17.05L9.075 14.575C8.84167 14.375 8.575 14.2292 8.275 14.1375C7.975 14.0458 7.675 14 7.375 14C7.05833 14 6.75417 14.0458 6.4625 14.1375C6.17083 14.2292 5.90833 14.375 5.675 14.575L3.675 16.275Z" fill="#D84727"/>
                        </svg>
                        Clothings
                    </button>
                    <button className=' border font-normal lg:mx-3 outline-none rounded-[40px] text-sm gap-2 flex items-center border-[#F8DAC9] px-4 h-[40px] ' >
                        <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5 16H1.5C1.0875 16 0.734375 15.8531 0.440625 15.5594C0.146875 15.2656 0 14.9125 0 14.5V1.5C0 1.0875 0.146875 0.734375 0.440625 0.440625C0.734375 0.146875 1.0875 0 1.5 0H18V1.5H1.5V14.5H5V16ZM7.5 16V14.35C7.2 14.0667 6.95833 13.7458 6.775 13.3875C6.59167 13.0292 6.5 12.65 6.5 12.25C6.5 11.85 6.59167 11.4708 6.775 11.1125C6.95833 10.7542 7.2 10.4333 7.5 10.15V8.5H11V10.15C11.3 10.4333 11.5417 10.7542 11.725 11.1125C11.9083 11.4708 12 11.85 12 12.25C12 12.65 11.9083 13.0292 11.725 13.3875C11.5417 13.7458 11.3 14.0667 11 14.35V16H7.5ZM9.24705 14C9.73235 14 10.1458 13.8301 10.4875 13.4904C10.8292 13.1507 11 12.7382 11 12.2529C11 11.7676 10.8302 11.3542 10.4905 11.0125C10.1507 10.6708 9.73823 10.5 9.25295 10.5C8.76765 10.5 8.35417 10.6699 8.0125 11.0096C7.67083 11.3493 7.5 11.7618 7.5 12.2471C7.5 12.7324 7.66985 13.1458 8.00955 13.4875C8.34927 13.8292 8.76177 14 9.24705 14ZM19.25 16H14.25C14.05 16 13.875 15.925 13.725 15.775C13.575 15.625 13.5 15.45 13.5 15.25V6.25C13.5 6.0375 13.575 5.85938 13.725 5.71563C13.875 5.57188 14.05 5.5 14.25 5.5H19.25C19.4625 5.5 19.6406 5.57188 19.7844 5.71563C19.9281 5.85938 20 6.0375 20 6.25V15.25C20 15.45 19.9281 15.625 19.7844 15.775C19.6406 15.925 19.4625 16 19.25 16ZM15 14.5H18.5V7H15V14.5Z" fill="#D84727"/>
                        </svg>
                        Gadgets
                    </button>
                    <button className=' border font-normal lg:mx-3 outline-none rounded-[40px] text-sm gap-2 flex items-center border-[#F8DAC9] px-4 h-[40px] ' >
                        <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0 14V7.625C0 7.20833 0.0833333 6.81667 0.25 6.45C0.416667 6.08333 0.666667 5.78333 1 5.55V2.65C1 1.9 1.25417 1.27083 1.7625 0.7625C2.27083 0.254167 2.9 0 3.65 0H8.15C8.51667 0 8.85833 0.0833333 9.175 0.25C9.49167 0.416667 9.76667 0.641667 10 0.925C10.2333 0.641667 10.5042 0.416667 10.8125 0.25C11.1208 0.0833333 11.4583 0 11.825 0H16.325C17.075 0 17.7083 0.254167 18.225 0.7625C18.7417 1.27083 19 1.9 19 2.65V5.55C19.3333 5.78333 19.5833 6.08333 19.75 6.45C19.9167 6.81667 20 7.20833 20 7.625V14H18.5V12H1.5V14H0ZM10.75 5.125H17.5V2.65C17.5 2.31667 17.3875 2.04167 17.1625 1.825C16.9375 1.60833 16.6583 1.5 16.325 1.5H11.75C11.4667 1.5 11.2292 1.61667 11.0375 1.85C10.8458 2.08333 10.75 2.35 10.75 2.65V5.125ZM2.5 5.125H9.25V2.65C9.25 2.35 9.15417 2.08333 8.9625 1.85C8.77083 1.61667 8.53333 1.5 8.25 1.5H3.65C3.33333 1.5 3.0625 1.6125 2.8375 1.8375C2.6125 2.0625 2.5 2.33333 2.5 2.65V5.125ZM1.5 10.5H18.5V7.625C18.5 7.34167 18.4042 7.10417 18.2125 6.9125C18.0208 6.72083 17.7833 6.625 17.5 6.625H2.5C2.21667 6.625 1.97917 6.72083 1.7875 6.9125C1.59583 7.10417 1.5 7.34167 1.5 7.625V10.5Z" fill="#D84727"/>
                        </svg>
                        Furniture
                    </button>
                    <button className=' border font-normal lg:mx-3 outline-none rounded-[40px] text-sm gap-2 flex items-center border-[#F8DAC9] px-4 h-[40px] ' >
                        <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0 18V1.5C0 1.0875 0.146875 0.734375 0.440625 0.440625C0.734375 0.146875 1.0875 0 1.5 0H14.5C14.9125 0 15.2656 0.146875 15.5594 0.440625C15.8531 0.734375 16 1.0875 16 1.5V18H14.5V16H1.5V18H0ZM1.5 8H7.25V1.5H1.5V8ZM8.75 4H14.5V1.5H8.75V4ZM8.75 8H14.5V5.5H8.75V8ZM6.25 12.75H9.75V11.25H6.25V12.75ZM1.5 9.5V14.5H14.5V9.5H1.5Z" fill="#D84727"/>
                        </svg>
                        Household_items
                    </button> 
                {/* </Marquee> */}
            </div>
        </div>
    )
}
