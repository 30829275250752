import React from 'react' 
import FlipNumbers from 'react-flip-numbers';

export default function SecondSection() {

    const [number, setNumber] = React.useState(2980)

    const t1 = setTimeout(() => {
        
        if(number !== 3000){
            setNumber(number+1)
        } 
        clearTimeout(t1);
    }, 1000);

    return (
        <div className=' w-full relative h-[255px] lg:h-[330px] ' >
            <img alt='Second' src='images/Second.png' className=' w-full h-[255px] lg:h-[330px] object-cover ' />
            <div className=' absolute inset-0 w-full flex lg:flex-row flex-col items-center justify-center bg-[#032857] bg-opacity-90 ' >
                <p  style={{fontFamily: 'Grotesque'}} className=' text-[24px] lg:text-[32px] text-white ' >Now at</p>
                <div  className=' hidden lg:flex items-center !text-base gap-2 lg:gap-4 lg:ml-12 lg:mt-0 mt-4 lg:w-auto w-full lg:px-0 px-6 ' >
                    <FlipNumbers height={196} width={175} color="#032857" background="#FDD553" numberStyle={{fontSize: "56px",fontFamily: 'GrotesqueExtra'}} numberClassName=' rounded-[24px] mx-4 !font-semibold' play numbers={`${number}`} /> 
                </div> 
                <div className=' lg:hidden  flex overflow-hidden items-center justify-center !text-base gap-2 lg:gap-4 lg:ml-12 lg:mt-0 mt-4 lg:w-auto w-full lg:px-0 px-6 ' >
                    <FlipNumbers height={94} width={70} color="#032857" background="#FDD553" numberStyle={{fontSize: "56px", fontFamily: 'GrotesqueExtra'}} numberClassName=' rounded-[16px] mx-2 !font-semibold ' play numbers={`${number}`} />
                </div>
            </div>
        </div>
    )
}
