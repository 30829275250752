import React from 'react'

export default function FourSection() {
    return (
        <div className=' px-6 lg:px-[50px] py-24 w-full flex flex-col items-center justify-center  ' >
            <p style={{fontFamily: 'Grotesque'}} className=' text-[40px] text-center lg:text-[48px] mb-9 ' >Why join priority list?</p>
            <div className='w-full flex lg:flex-row flex-col h-auto justify-center items-center gap-6 ' >
                <div className=' w-fit p-[20px] lg:p-[40px] lg:h-[300px] rounded-[24px] border border-[#E1EAD7] bg-[#FAFCF8] ' >
                    <svg width="98" height="55" viewBox="0 0 98 55" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2 10C2 5.58172 5.58172 2 10 2H86C90.4183 2 94 5.58172 94 10V39.6655C94 43.8151 90.8271 47.2759 86.693 47.6354L10.693 54.2441C6.01891 54.6505 2 50.9659 2 46.2742L2 10Z" fill="#638042"/>
                        <path d="M5.87901 7.42655C6.50923 3.25137 10.2758 0.284855 14.4824 0.650647L90.2854 7.24221C94.9674 7.64934 98.2915 11.9853 97.4689 16.6124L92.1733 46.4003C91.4945 50.2185 88.1749 53 84.2968 53H8.29817C3.40425 53 -0.342654 48.6451 0.387777 43.806L5.87901 7.42655Z" fill="#7CA154"/>
                        <path d="M37.0024 33.056C35.8264 31.52 35.2504 29.36 35.2504 26.6C35.2504 23.84 35.8264 21.68 37.0024 20.144C38.1784 18.608 39.9784 17.816 42.4024 17.816C44.8024 17.816 46.5784 18.608 47.7544 20.144C48.9064 21.704 49.5064 23.864 49.5064 26.6C49.5064 29.384 48.9064 31.52 47.7304 33.056C46.5544 34.592 44.7784 35.36 42.4024 35.36C39.9784 35.36 38.1784 34.592 37.0024 33.056ZM44.8744 31.088C45.4024 30.104 45.6904 28.616 45.6904 26.6C45.6904 24.584 45.4024 23.096 44.8744 22.112C44.3464 21.128 43.5064 20.624 42.4024 20.624C41.2504 20.624 40.3864 21.128 39.8584 22.088C39.3304 23.072 39.0664 24.584 39.0664 26.6C39.0664 28.616 39.3304 30.128 39.8584 31.088C40.3864 32.072 41.2504 32.552 42.4024 32.552C43.5064 32.552 44.3464 32.072 44.8744 31.088ZM54.4789 22.424C54.0949 23.144 53.6149 23.696 53.0389 24.032C52.4629 24.368 51.6469 24.536 50.6149 24.536H50.3269V21.176C51.7909 21.176 52.9669 20.936 53.8789 20.408C54.7909 19.88 55.3189 19.16 55.5109 18.2H58.3189V35H54.6229V22.448L54.4789 22.424Z" fill="#FAFCF8"/>
                    </svg>
                    <p style={{fontFamily: 'Grotesque'}} className=' text-[#1B1C1E] text-[24px] font-bold mt-8 ' >Be the first to test</p>
                    <p className=' mt-4 text-[#616161] max-w-[300px]   ' >We’re building the worlds first automated giving solution. We want you to be the first to see and use it</p>
                </div>
                <div className=' w-fit p-[20px] lg:p-[40px] lg:h-[300px] rounded-[24px] border border-[#E1EAD7] bg-[#FAFCF8] ' >
                    <svg width="98" height="55" viewBox="0 0 98 55" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2.33337 10C2.33337 5.58172 5.9151 2 10.3334 2H86.3334C90.7517 2 94.3334 5.58172 94.3334 10V39.6655C94.3334 43.8151 91.1605 47.2759 87.0264 47.6354L11.0264 54.2441C6.35228 54.6505 2.33337 50.9659 2.33337 46.2742L2.33337 10Z" fill="#638042"/>
                        <path d="M6.21238 7.42655C6.8426 3.25137 10.6092 0.284855 14.8158 0.650647L90.6188 7.24221C95.3008 7.64934 98.6249 11.9853 97.8023 16.6124L92.5067 46.4003C91.8279 50.2185 88.5083 53 84.6302 53H8.63154C3.73762 53 -0.0092802 48.6451 0.721151 43.806L6.21238 7.42655Z" fill="#7CA154"/>
                        <path d="M35.4725 33.056C34.2965 31.52 33.7205 29.36 33.7205 26.6C33.7205 23.84 34.2965 21.68 35.4725 20.144C36.6485 18.608 38.4485 17.816 40.8725 17.816C43.2725 17.816 45.0485 18.608 46.2245 20.144C47.3765 21.704 47.9765 23.864 47.9765 26.6C47.9765 29.384 47.3765 31.52 46.2005 33.056C45.0245 34.592 43.2485 35.36 40.8725 35.36C38.4485 35.36 36.6485 34.592 35.4725 33.056ZM43.3445 31.088C43.8725 30.104 44.1605 28.616 44.1605 26.6C44.1605 24.584 43.8725 23.096 43.3445 22.112C42.8165 21.128 41.9765 20.624 40.8725 20.624C39.7205 20.624 38.8565 21.128 38.3285 22.088C37.8005 23.072 37.5365 24.584 37.5365 26.6C37.5365 28.616 37.8005 30.128 38.3285 31.088C38.8565 32.072 39.7205 32.552 40.8725 32.552C41.9765 32.552 42.8165 32.072 43.3445 31.088ZM48.3556 31.976L49.6276 31.04C51.5956 29.624 53.0596 28.52 54.0676 27.704C55.0516 26.912 55.8436 26.12 56.4436 25.328C57.0436 24.56 57.3556 23.768 57.3556 23C57.3556 22.28 57.1156 21.704 56.6836 21.272C56.2276 20.864 55.6276 20.648 54.8596 20.648C53.9956 20.648 53.3236 20.912 52.8436 21.392C52.3636 21.872 52.0996 22.592 52.0756 23.552H48.5476C48.5956 21.656 49.1716 20.24 50.2996 19.28C51.4036 18.32 52.9396 17.84 54.8596 17.84C56.6836 17.84 58.1476 18.296 59.2996 19.184C60.4516 20.072 61.0276 21.32 61.0276 22.904C61.0276 24.008 60.6916 25.04 60.0676 25.976C59.4436 26.912 58.6516 27.752 57.7156 28.496C56.7556 29.24 55.4836 30.152 53.8516 31.256L52.8436 31.928L52.9156 32.096C53.4436 32.048 54.0436 32 54.6916 31.976C55.3396 31.976 56.0356 31.952 56.8036 31.952H60.7876V35H48.3556V31.976Z" fill="#FAFCF8"/>
                    </svg>
                    <p style={{fontFamily: 'Grotesque'}} className=' text-[#1B1C1E] text-[24px] font-bold mt-8 ' >Start getting g-coins</p>
                    <p className=' mt-4 text-[#616161] max-w-[300px]   ' >For trusting us, we create your g-coin wallet and credit it right away!</p>
                </div>
                <div className=' w-fit p-[20px] lg:p-[40px] lg:h-[300px] rounded-[24px] border border-[#E1EAD7] bg-[#FAFCF8] ' >
                    <svg width="99" height="55" viewBox="0 0 99 55" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2.66675 10C2.66675 5.58172 6.24847 2 10.6667 2H86.6667C91.085 2 94.6667 5.58172 94.6667 10V39.6655C94.6667 43.8151 91.4938 47.2759 87.3598 47.6354L11.3598 54.2441C6.68566 54.6505 2.66675 50.9659 2.66675 46.2742L2.66675 10Z" fill="#638042"/>
                        <path d="M6.54576 7.42655C7.17598 3.25137 10.9426 0.284855 15.1492 0.650647L90.9522 7.24221C95.6341 7.64934 98.9582 11.9853 98.1357 16.6124L92.84 46.4003C92.1612 50.2185 88.8416 53 84.9635 53H8.96492C4.071 53 0.324094 48.6451 1.05452 43.806L6.54576 7.42655Z" fill="#7CA154"/>
                        <path d="M35.3957 33.056C34.2197 31.52 33.6437 29.36 33.6437 26.6C33.6437 23.84 34.2197 21.68 35.3957 20.144C36.5717 18.608 38.3717 17.816 40.7957 17.816C43.1957 17.816 44.9717 18.608 46.1477 20.144C47.2997 21.704 47.8997 23.864 47.8997 26.6C47.8997 29.384 47.2997 31.52 46.1237 33.056C44.9477 34.592 43.1717 35.36 40.7957 35.36C38.3717 35.36 36.5717 34.592 35.3957 33.056ZM43.2677 31.088C43.7957 30.104 44.0837 28.616 44.0837 26.6C44.0837 24.584 43.7957 23.096 43.2677 22.112C42.7397 21.128 41.8997 20.624 40.7957 20.624C39.6437 20.624 38.7797 21.128 38.2517 22.088C37.7237 23.072 37.4597 24.584 37.4597 26.6C37.4597 28.616 37.7237 30.128 38.2517 31.088C38.7797 32.072 39.6437 32.552 40.7957 32.552C41.8997 32.552 42.7397 32.072 43.2677 31.088ZM57.8257 26.168C59.0977 26.312 60.1057 26.72 60.8257 27.416C61.5457 28.112 61.9057 29.024 61.9057 30.152C61.9057 31.28 61.6177 32.24 61.0417 33.008C60.4657 33.8 59.6737 34.4 58.6897 34.784C57.7057 35.192 56.6017 35.384 55.3777 35.384C53.3137 35.384 51.6337 34.904 50.3857 33.92C49.1137 32.96 48.4657 31.472 48.4657 29.48H51.9937C51.9937 30.56 52.3057 31.352 52.9057 31.832C53.4817 32.336 54.2977 32.576 55.3057 32.576C56.1937 32.576 56.8897 32.36 57.3697 31.904C57.8497 31.472 58.1137 30.848 58.1137 30.056C58.1137 29.288 57.8497 28.688 57.3697 28.256C56.8657 27.848 56.1697 27.632 55.2577 27.632H53.8417V25.016H55.3537C56.1697 25.016 56.7937 24.824 57.2257 24.44C57.6577 24.056 57.8977 23.504 57.8977 22.808C57.8977 22.136 57.6337 21.608 57.1537 21.224C56.6737 20.84 56.0497 20.648 55.2817 20.648C53.3617 20.648 52.4017 21.632 52.4017 23.552H48.8497C48.8497 21.632 49.4737 20.192 50.6737 19.256C51.8737 18.32 53.4577 17.84 55.3777 17.84C56.4817 17.84 57.4897 18.032 58.4017 18.392C59.3137 18.752 60.0577 19.28 60.6097 19.976C61.1377 20.672 61.4257 21.488 61.4257 22.424C61.4257 23.36 61.1137 24.176 60.4897 24.848C59.8657 25.544 58.9777 25.928 57.8257 26V26.168Z" fill="#FAFCF8"/>
                    </svg>
                    <p style={{fontFamily: 'Grotesque'}} className=' text-[#1B1C1E] text-[24px] font-bold mt-8 ' >Exclusive access</p>
                    <p className=' mt-4 text-[#616161] max-w-[300px]   ' >To life changing stories, tips and tricks to live fully.</p>
                </div>
            </div>
        </div>
    )
}
