import React from 'react' 
import ReactPlayer from 'react-player'

export default function ThirdSection() {
    return (
        <> 
            <div className=' py-24 w-full px-6 lg:px-[50px] lg:flex-row flex-col flex items-center justify-center lg:gap-16 ' >
                <div className=' w-full lg:w-[40vw]  lg:px-[40px] ' >
                    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="0.75" y="0.75" width="46.5" height="46.5" rx="7.25" fill="#FDF8F6"/>
                        <path d="M24 12L25.2305 16.9402C25.9457 19.812 28.188 22.0543 31.0598 22.7695L36 24L31.0598 25.2305C28.188 25.9457 25.9457 28.188 25.2305 31.0598L24 36L22.7695 31.0598C22.0543 28.188 19.812 25.9457 16.9402 25.2305L12 24L16.9402 22.7695C19.812 22.0543 22.0543 19.812 22.7695 16.9402L24 12Z" fill="#D84727"/>
                        <rect x="0.75" y="0.75" width="46.5" height="46.5" rx="7.25" stroke="#F6D3CB" stroke-width="1.5"/>
                    </svg>
                    <p style={{fontFamily: 'Grotesque'}} className=' mt-8 font-bold leading-[40px] lg:leading-[56px] text-[32px] lg:text-[48px]  ' >Give without stress</p>
                    <p className=' mt-6 font-normal text-lg text-[#616161] lg:max-w-md ' >Whether you want to clear the clutter or show some kindness, you’ll be able to do it without stress</p>
                </div>
                <div className='lg:mt-0 mt-8  w-full lg:w-fit p-7 lg:p-10  bg-[#FEF9F6] lg:h-auto rounded-[24px] ' >
                    <img alt='Four' src='images/ThirdFirst.png' className=' w-full block lg:w-[35vw] ' />
                </div>
            </div>
            <div className=' lg:pb-14 pb-12 lg:py-14 w-full px-6 lg:px-[50px] lg:flex-row flex-col-reverse flex items-center justify-center lg:gap-16 ' >
                <img alt='Four' src='images/Third.png' className=' w-full block lg:mt-0 mt-8 lg:w-[40vw] ' />
                {/* <img alt='Four' src='images/MobileImage.png' className=' w-full lg:hidden lg:mt-0 mt-8 lg:w-[40vw] ' /> */}
                <div className=' w-full lg:w-[40vw]  lg:px-[40px] ' >
                    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="0.75" y="0.75" width="46.5" height="46.5" rx="7.25" fill="#FDF8F6"/>
                        <path d="M24 12L25.2305 16.9402C25.9457 19.812 28.188 22.0543 31.0598 22.7695L36 24L31.0598 25.2305C28.188 25.9457 25.9457 28.188 25.2305 31.0598L24 36L22.7695 31.0598C22.0543 28.188 19.812 25.9457 16.9402 25.2305L12 24L16.9402 22.7695C19.812 22.0543 22.0543 19.812 22.7695 16.9402L24 12Z" fill="#D84727"/>
                        <rect x="0.75" y="0.75" width="46.5" height="46.5" rx="7.25" stroke="#F6D3CB" stroke-width="1.5"/>
                    </svg>
                    <p style={{fontFamily: 'Grotesque'}} className=' mt-8 font-bold lg:max-w-xl  leading-[40px] lg:leading-[56px] text-[32px] lg:text-[48px]  ' >Request anything without bounds</p>
                    <p className=' mt-6 font-normal text-lg text-[#616161] lg:max-w-md ' >If you really need it, someone will give it</p>
                </div>
            </div>
            <div className=' py-16 bg-[#F5F9FF] lg:py-24 w-full px-6 lg:px-[50px] lg:flex-row flex-col flex items-center justify-center lg:gap-16 ' >
                <div className='  w-full lg:w-[40vw]  lg:px-[40px] ' >
                    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="0.75" y="0.75" width="46.5" height="46.5" rx="7.25" fill="#FDF8F6"/>
                        <path d="M24 12L25.2305 16.9402C25.9457 19.812 28.188 22.0543 31.0598 22.7695L36 24L31.0598 25.2305C28.188 25.9457 25.9457 28.188 25.2305 31.0598L24 36L22.7695 31.0598C22.0543 28.188 19.812 25.9457 16.9402 25.2305L12 24L16.9402 22.7695C19.812 22.0543 22.0543 19.812 22.7695 16.9402L24 12Z" fill="#D84727"/>
                        <rect x="0.75" y="0.75" width="46.5" height="46.5" rx="7.25" stroke="#F6D3CB" stroke-width="1.5"/>
                    </svg>
                    <p style={{fontFamily: 'Grotesque'}} className=' mt-8 font-bold leading-[40px] lg:leading-[56px] text-[32px] lg:text-[48px] lg:max-w-md   ' >Get g-coins when you give</p>
                    <p className=' mt-6 font-normal text-lg text-[#616161] lg:max-w-md ' >When you give, we reward you with g-coins you can use to shop on over 20 web stores.</p>
                </div>
                <div className=' lg:mt-0 mt-8 w-full lg:w-[40vw] flex justify-center  h-[328px] rounded-[24px] ' >
                {/* https://youtu.be/svXuGK8dC9w */}
                    <video className=' lg:w-[40vw] rounded-lg h-[328px] '
                            autoPlay={true} 
                            loop={true}
                            controls={false} 
                            playsInline
                            muted   >
                    <source src="/video.mp4" type="video/mp4"/>
                    </video>

{/* <ReactPlayer controls={true} playing={true} loop={true} url='https://youtu.be/svXuGK8dC9w' /> */}
                </div>
            </div>
            <div className=' lg:mt-0 mt-8 lg:py-14 w-full px-6 lg:px-[50px] lg:flex-row  flex-col-reverse flex items-center justify-center gap-12 lg:gap-16 ' >
                <img alt='Four' src='images/Four.png' className=' w-full lg:w-[40vw] ' />
                <div className=' lg:mt-0 w-full lg:w-[40vw]  lg:px-[40px] ' >
                    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="0.75" y="0.75" width="46.5" height="46.5" rx="7.25" fill="#FDF8F6"/>
                        <path d="M24 12L25.2305 16.9402C25.9457 19.812 28.188 22.0543 31.0598 22.7695L36 24L31.0598 25.2305C28.188 25.9457 25.9457 28.188 25.2305 31.0598L24 36L22.7695 31.0598C22.0543 28.188 19.812 25.9457 16.9402 25.2305L12 24L16.9402 22.7695C19.812 22.0543 22.0543 19.812 22.7695 16.9402L24 12Z" fill="#D84727"/>
                        <rect x="0.75" y="0.75" width="46.5" height="46.5" rx="7.25" stroke="#F6D3CB" stroke-width="1.5"/>
                    </svg>
                    <p style={{fontFamily: 'Grotesque'}} className=' mt-8 font-bold lg:max-w-xl  leading-[40px] lg:leading-[56px] text-[32px] lg:text-[48px]  ' >We pick up from the <span className=' text-[#E9B202] ' >giver</span> and drop off with the new <span className=' text-[#638042] ' >owner</span></p>
                    <p className=' mt-6 font-normal text-lg text-[#616161] lg:max-w-md ' >If you really need it, someone will give it</p>
                </div>
            </div>
        </>
    )
}
