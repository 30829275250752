import React from 'react';
import logo from './logo.svg';
import './App.css';
import Navbar from './components/Navbar';
import HeroSection from './components/HeroSection';
import CategoriesSection from './components/CategoriesSection';
import SecondSection from './components/SecondSection';
import ThirdSection from './components/ThirdSection';
import FourSection from './components/FourthSection';
import FifthSection from './components/FifthSection';
import SixthSection from './components/SixthSection';
import Footer from './components/Footer/Footer';

function App() {
  return (
    <div className=" w-full "> 
      <Navbar />
      <HeroSection />
      <CategoriesSection />
      <SecondSection />
      <ThirdSection />
      <FourSection />
      <FifthSection />
      <SixthSection />
      <Footer />
    </div>
  );
}

export default App;
