import React from 'react'
import { Modal } from '../HeroSection/modal'

export default function FifthSection() {
    return (
        <div className='lg:py-24 lg:pb-24 pb-12 w-full px-6 lg:px-[50px] flex justify-center ' > 
            <div className='h-[450px] w-full lg:w-[70vw] relative ' >
                <img alt='Fifth' src='images/Fifth.png' className=' h-[450px] w-full lg:w-[70vw] object-cover rounded-[24px] ' /> 
                <div className=' absolute inset-0 flex flex-col justify-center items-center  rounded-[24px] text-center bg-[#032857] bg-opacity-80 lg:px-0 px-3 ' >
                    <p  style={{fontFamily: 'Grotesque'}} className=' lg:max-w-xl font-bold text-[32px] lg:text-[48px] leading-[45px] lg:leading-[60px] text-white ' >The world goes round because of <span className=' text-[#FDC921] ' >you!</span> 🫵🏽 </p>
                    {/* <button className=' gap-3 flex items-center font-medium text-white px-8 rounded-[40px] bg-[#DE644A] mt-9 h-[40px] ' >
                        Join Priority List
                        <svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.34996 8.96667L6.63329 8.26667L9.43329 5.46667H0.666626V4.46667H9.43329L6.61663 1.65L7.33329 0.950001L11.35 4.96667L7.34996 8.96667Z" fill="white"/>
                        </svg>
                    </button>  */}
                    <Modal />
                </div>
            </div>
        </div>
    )
} 