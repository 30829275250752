import React from 'react'
import { Modal } from './modal';

export default function HeroSection() {


    const el: any = document.querySelector('html');  
    const [isShown, setIsShown] = React.useState(false)
    const [tab, setTab] = React.useState(false)
    let t1: any = null
    const [isActive, setisActive] = React.useState("one")

    React.useEffect(()=> {
        if(isShown){
            el.style.overflow = 'hidden';
            } else {
            el.style.overflow = 'auto';
        }
    }, [isShown])  

    const clickHandler =()=> {
        setIsShown(false)
        setTab(false)
    } 



    React.useEffect(()=> {
        
        t1  = setTimeout(() => {
            if(isActive === "one"){
                changeImage("two")
            } else if(isActive === "two"){
                changeImage("three")
            } else if(isActive === "three"){
                changeImage("one")
            } 

            console.log(isActive);
            
            return () => {
                clearInterval(t1);
            };
        }, 5000);  
    },[isActive])

    const changeImage =(item: any)=> { 
        let newdata = item 
        setisActive(newdata) 
        clearInterval(t1);
    }


    return (
        <div className=' w-full flex flex-col text-center items-center pt-14 lg:pt-24 px-6 lg:px-[50px] ' >
            {isActive === "one" && (
                <> 
                    <p style={{fontFamily: 'Grotesque', fontWeight: "700"}} className=' font-bold text-[48px] lg:text-[64px] lg:max-w-2xl leading-[56px] lg:leading-[72px] ' >It’s easy, fast and safe to give now</p>
                    <p className=' lg:max-w-lg text-lg font-normal mt-6 ' >So, how about you keep the things you need and send the rest to others in a box of kindness.</p>
                </>
            )}
            {isActive === "two" && (
                <> 
                    <p style={{fontFamily: 'Grotesque', fontWeight: "700"}} className=' font-bold text-[48px] lg:text-[64px] lg:max-w-2xl leading-[56px] lg:leading-[72px] ' >Clear the clutter with purpose</p>
                    <p className=' lg:max-w-lg text-lg font-normal mt-6 ' >Giving and kindness work together. They even say it’s therapeutic.</p>
                </>
            )}
            {isActive === "three" && (
                <> 
                    <p style={{fontFamily: 'Grotesque', fontWeight: "700"}} className=' font-bold text-[48px] lg:text-[64px] lg:max-w-sm leading-[56px] lg:leading-[72px] ' >Give safely. Get easy</p>
                    <p className=' lg:max-w-lg text-lg font-normal mt-6 ' >Pass on new and used items to others instead of letting them clutter your space.</p>
                </>
            )}
            <Modal />
            <div className=' w-full flex justify-center gap-2 lg:gap-6 my-16 h-[240px] lg:h-[350px] ' >
                <img src='images/Hero.png' alt='Hero' onClick={()=> changeImage("one")} className={isActive === "one" ? ' rounded-xl w-[60%] object-cover h-[240px] lg:h-[350px] ': ' rounded-xl w-[10%] object-cover h-[240px] lg:h-[350px] '} /> 
                <img src='images/Hero2.png' alt='Hero' onClick={()=> changeImage("two")} className={isActive === "two" ? ' rounded-xl w-[60%] object-cover h-[240px] lg:h-[350px] ': ' rounded-xl w-[10%] object-cover h-[240px] lg:h-[350px] '} /> 
                <img src='images/Hero1.png' alt='Hero' onClick={()=> changeImage("three")} className={isActive === "three" ? ' rounded-xl w-[60%] object-cover h-[240px] lg:h-[350px] ': ' rounded-xl w-[10%] object-cover h-[240px] lg:h-[350px] '} /> 
            </div>
        </div>
    )
}
