import React from 'react'

export default function SixthSection() { 

    const [message, setMessage] = React.useState("")

    const ButtonMailto = ({ mailto, label }: any) => {
        return (
            <a
                href='#'
                onClick={(e) => {
                    window.location.href = mailto;
                    e.preventDefault();
                }}
                target="_blank" className=' w-full bg-[#FDF8F6] h-[56px] lg:h-[50px] border border-[#EEAEA0] rounded-lg flex items-center px-4 '
            >
                <img alt='mail' className='mr-4' src='images/gmail.png' />
                {label}
                <svg width="17" className='ml-auto' height="13" viewBox="0 0 17 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.025 12.9498L8.95 11.8998L13.15 7.69981H0V6.19981H13.15L8.925 1.9748L10 0.924805L16.025 6.94981L10.025 12.9498Z" fill="black"/>
                </svg>  
            </a>
        );
    };

    return (
        <div className=' bg-[#F5F9FF] px-6 lg:px-[50px] lg:pb-24 pb-12 lg:py-24 w-full flex flex-col items-center justify-center  ' >
            <p style={{fontFamily: 'Grotesque'}} className=' text-[48px] mb-9 ' >Talk to us</p>
            <div className=' w-full lg:max-w-[558px] flex flex-col ' >
                <p style={{fontFamily: 'Grotesque'}} className=' !font-medium mb-3 text-lg ' >Leave a message</p>
                <textarea onChange={(e)=> setMessage(e.target.value)} className=' p-4 bg-[#F5F9FF] border w-full lg:w-[558px] h-[108px] ' />
                {message && (
                    <a 
                        href='#'
                        onClick={(e) => {
                            window.location.href = "mailto:geevahq@gmail.com?subject=Talk to us&body="+message;
                            e.preventDefault();
                        }}
                        className=' gap-3 flex items-center font-medium text-white px-8 w-fit ml-auto rounded-[40px] bg-[#DE644A] mt-6 h-[40px] ' >
                        Send Message
                    </a>
                )}
                {!message && (
                    <button 
                        disabled={true} 
                        className=' gap-3 flex items-center font-medium text-white px-8 w-fit ml-auto rounded-[40px] bg-[#9E9E9E] mt-6 h-[40px] ' >
                        Send Message
                    </button>
                )}
                <div style={{fontFamily: 'Grotesque'}} className=' w-full lg:w-[558px] mt-8 flex lg:flex-row flex-col gap-4 lg:gap-6 ' >
                    {/* <a target="_blank" href='' className=' w-full bg-[#FDF8F6] h-[56px] lg:h-[50px] border border-[#EEAEA0] rounded-lg flex items-center px-4 ' >
                        <img alt='mail' className='mr-4' src='images/gmail.png' />
                        Email 
                        <svg width="17" className='ml-auto' height="13" viewBox="0 0 17 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.025 12.9498L8.95 11.8998L13.15 7.69981H0V6.19981H13.15L8.925 1.9748L10 0.924805L16.025 6.94981L10.025 12.9498Z" fill="black"/>
                        </svg>  
                    </a> */}
                    <ButtonMailto label="Email" mailto="mailto:geevahq@gmail.com" />
                    <a target="_blank" href='https://wa.me/qr/XQTJ7TCWCIHSE1' className=' w-full bg-[#FAFCF8] h-[56px] lg:h-[50px] border border-[#C4D6B0] rounded-lg flex items-center px-4 ' >
                        <img alt='mail' className='mr-4' src='images/whatsapp.png' />
                        Whatsapp
                        <svg width="17" className='ml-auto' height="13" viewBox="0 0 17 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.025 12.9498L8.95 11.8998L13.15 7.69981H0V6.19981H13.15L8.925 1.9748L10 0.924805L16.025 6.94981L10.025 12.9498Z" fill="black"/>
                        </svg>  
                    </a>
                </div>
            </div>
        </div>
    )
}